export class Message {
  messageId: Number | undefined = undefined
  messageType: String | undefined = undefined
  senderId: Number | undefined = undefined
  senderFullName: String | undefined = undefined
  recipientFullName: String | undefined = undefined
  recipientAddress: String | undefined = 'todo' // todo: backend fetch recipient address
  deliveryStatus: String | undefined = undefined
  deleted: Boolean | undefined = undefined

  // not search parameters
  senderMeansOfContact: String | undefined = undefined
  recipientMeansOfContact: String | undefined = undefined
  priority: Boolean | undefined = undefined
  campaign: String | undefined = undefined //todo
  received: String | undefined = undefined // Datetime referring to: opened / sent
  receivedDate: String | undefined = undefined
  subject: String | undefined = undefined
  body: String | undefined = undefined
  attachments: Boolean | undefined = undefined
  channel: String | undefined = undefined
  tags: any | undefined = undefined
  read: Boolean | undefined = undefined
  from: String | undefined = undefined
  isHtml: String | undefined = undefined
  uuid: String | undefined = undefined

  constructor(id: Number | undefined) {
    this.messageId = id
  }
}
