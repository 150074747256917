<template>
  <v-dialog v-model="dialog" max-width="800px" @blur="dialog = false">
    <v-card>
      <v-card-title>
        <span v-html="this.$t('search_message_detail_header')" />
      </v-card-title>
      <v-card-text>
        <v-form ref="detail" :readonly="true">
          <v-row>
            <v-col cols="12" sm="6">
              <!-- Absender Name -->
              <v-text-field
                v-model="message.senderFullName"
                type="text"
                id="search_message_detail_senderFullName"
                :label="$t('search_message_detail_senderFullName')"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <!-- Empfänger -->
              <v-text-field
                v-model="message.recipientFullName"
                type="text"
                id="search_message_detail_recipientFullName"
                :label="$t('search_message_detail_recipientFullName')"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <!-- Absender Email -->
              <v-text-field
                v-model="message.senderMeansOfContact"
                type="text"
                id="search_message_detail_recipientContactId"
                :label="$t('search_message_detail_senderMeansOfContact')"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <!-- Empfänger Anschrift -->
              <v-text-field
                v-model="message.recipientMeansOfContact"
                type="text"
                id="search_message_detail_recipientContactId"
                :label="$t('search_message_detail_recipientMeansOfContact')"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <!-- Empfangen/gesendet -->
              <v-text-field v-model="sent" type="text" id="search_message_detail_received" :label="$t('search_message_detail_received')" />
            </v-col>
            <v-col cols="12" sm="6">
              <!-- Kanal -->
              <v-text-field v-model="message.channel" type="text" id="search_message_detail_channel" :label="$t('search_message_detail_channel')" />
            </v-col>
            <v-col cols="12">
              <!-- Betreff -->
              <v-text-field
                maxlength="120"
                v-model="message.subject"
                type="text"
                id="search_message_detail_subject"
                :label="$t('search_message_detail_subject')"
              />
            </v-col>

            <v-col cols="12">
              <!-- Nachricht -->
              <v-textarea
                v-model="message.body"
                type="text"
                id="search_message_detail_body"
                variant="filled"
                filled
                auto-grow
                :label="$t('search_message_detail_body')"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <!-- Bezugsdatum -->
              <v-text-field v-model="message.sent" type="text" id="search_message_detail_receivedDate" :label="$t('search_message_detail_receivedDate')" />
            </v-col>
            <v-col cols="12" sm="6">
              <!-- Anhang -->
              <v-text-field v-model="message.attachments" type="text" id="search_message_detail_attachments" :label="$t('search_message_detail_attachments')" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="message.priority" type="text" id="search_message_detail_priority" :label="$t('search_message_detail_priority')" />
            </v-col>
            <v-col cols="12" sm="6">
              <!-- Status -->
              <v-text-field
                v-model="message.deliveryStatus"
                type="text"
                id="search_message_detail_deliveryStatus"
                :label="$t('search_message_detail_deliveryStatus')"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <!-- Gelesen am -->
              <v-text-field v-model="formatedRead" type="text" id="search_message_detail_read" :label="$t('search_message_detail_read')" />
            </v-col>
            <v-col cols="12" sm="6">
              <!-- Gelesen von -->
              <v-text-field v-model="message.from" type="text" id="search_message_detail_from" :label="$t('search_message_detail_from')" />
            </v-col>
            <v-col cols="12" sm="6">
              <!-- HTML-message -->
              <v-text-field v-model="message.isHtml" type="text" id="search_message_detail_htmlMessage" :label="$t('search_message_detail_htmlMessage')" />
            </v-col>
            <v-col cols="12" sm="6">
              <!-- Message-UUID Name -->
              <v-text-field v-model="message.uuid" type="text" id="search_message_detail_messageUuid" :label="$t('search_message_detail_messageUuid')" />
            </v-col>
            <v-col cols="12">
              <!-- Tags -->
              <v-text-field v-model="tagsStringified" type="text" id="search_message_detail_tags" :label="$t('search_message_detail_tags')" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn id="btn-edit-cancel" color="primary" class="button-margin button-top-margin shadow-none" @click="dialog = false">
          <span v-html="$t('search_message_detail_btn_close')"
        /></v-btn>
        <router-link :to="{ name: 'messages_read', params: {} }" class="tableaction link"
          ><!-- todo change route to message settings-->
          <v-btn
            id="btn-edit-to-settings"
            color="secondary"
            class="button-margin button-top-margin shadow-none"
            @click="dialog = false"
            v-html="$t('search_message_detail_to_settings')"
          ></v-btn>
        </router-link>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Message } from '@/views/messages/inbox/Message'

export default {
  name: 'messageDetail',
  components: { Message },
  props: {
    message: { type: Message, default: () => new Message(undefined) },
    showDialog: { type: Boolean, default: false }
  },
  data() {
    return {
      readOnly: true
    }
  },
  computed: {
    sent() {
      if (this.message.sent == null) return null
      return this.formatChDateTime(this.message.sent).substr(0, 10)
    },
    dialog: {
      get: function () {
        return this.showDialog
      },
      set: function (value) {
        this.$emit('closeDialog')
      }
    },
    tagsStringified: {
      get: function () {
        return this.message.tags ? JSON.stringify(this.message.tags) : '' //todo delete
      }
    },
    formatedRead: {
      get: function () {
        return this.message.read ? this.formatChDateTime(this.message.read).substr(0, 10) : ''
      }
    }
  },
  methods: {},
  watch: {},
  mounted() {}
}
</script>
